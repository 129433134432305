import styled, { keyframes, css } from "styled-components";

export const EixosDisplayContainer = styled.div`
  --height-10: calc(100px + ${(props) => props.overflowHeight * 0.1}px);
  --height-20: calc(100px + ${(props) => props.overflowHeight * 0.2}px);
  --height-30: calc(100px + ${(props) => props.overflowHeight * 0.3}px);
  --height-40: calc(100px + ${(props) => props.overflowHeight * 0.4}px);
  --height-50: calc(100px + ${(props) => props.overflowHeight * 0.5}px);
  --height-60: calc(100px + ${(props) => props.overflowHeight * 0.6}px);
  --height-70: calc(100px + ${(props) => props.overflowHeight * 0.7}px);
  --height-80: calc(100px + ${(props) => props.overflowHeight * 0.8}px);
  --height-90: calc(100px + ${(props) => props.overflowHeight * 0.9}px);
  --height-100: calc(100px + ${(props) => props.overflowHeight * 1}px);
  width: calc(100% - 1px);
  border-top: ${(props) => props.borderTop} solid #2b2b2b;
  border-bottom: ${(props) => props.borderBottom} solid #2b2b2b;
  border-left: ${(props) => props.borderLeft} solid #2b2b2b;
  border-right: ${(props) => props.borderRight} solid #2b2b2b;
  background-color: #ffffff;
  height: 100px;
  overflow: ${(props) => (props.showAnimation ? "visible" : "hidden")};
  ${({ showAnimation }) =>
    showAnimation &&
    css`
      animation: ${rollDown} 0.2s forwards;
    `}
  ${({ hideAnimation }) =>
    hideAnimation &&
    css`
      animation: ${rollUp} 0.2s forwards;
    `}
`;

export const rollDown = keyframes`
  0% {
    height:100px;
  }
  10% {
    height: var(--height-10);
  }
  20% {
    height: var(--height-20);
  }
  30% {
    height: var(--height-30);
  }
  40% {
    height: var(--height-40);
  }
  50% {
    height: var(--height-50);
  }
  60% {
    height: var(--height-60);
  }
  70% {
    height: var(--height-70);
  }
  80% {
    height: var(--height-80);
  }
  90% {
    height: var(--height-90);
  }
  100% {
    height: var(--height-100);
  }
`;

export const rollUp = keyframes`
  0% {
    height: var(--height-100);
  }
  10% {
    height: var(--height-100);
  }
  20% {
    height: var(--height-90);
  }
  30% {
    height: var(--height-80);
  }
  40% {
    height: var(--height-70);
  }
  50% {
    height: var(--height-60);
  }
  60% {
    height: var(--height-50);
  }
  70% {
    height: var(--height-40);
  }
  80% {
    height: var(--height-30);
  }
  90% {
    height: var(--height-20);
  }
  100% {
    height: 100px;
  }
`;
