import React from "react";
import Home from "../Home/Home.jsx";
import Eixos from "../Eixos/Eixos.jsx";
import Dates from "../Dates/Dates.jsx";
import Normes from "../Normes/Normes.jsx";
import OrganitzacióHome from "../OrganitzacióHome/OrganitzacióHome.jsx";
import Contacte from "../Contacte/Contacte.jsx";
import Patrocinadors from "../Patrocinadors/Patrocinadors.jsx";
const HomePage = () => {
  return (
    <>
      <Home />
      <Dates />
      <OrganitzacióHome />
      <Normes />
      <Eixos />
      <Contacte />
      <Patrocinadors />
    </>
  );
};

export default HomePage;
