import React, { useState } from "react";
import "./Navbar.css";
import NavbarItem from "../../units/NavbarItem/NavbarItem";
import logo from "../../../assets/Logotip_4t_Congrés_Blanc.png";
import logo2 from "../../../assets/Logo_CEC_BN_BLANC.png";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [displayHamburgerMenu, setDisplayHamburgerMenu] = useState(false);
  const { isMobile } = useDeviceWidth();

  return (
    <div className={"navbar__container navbar__container-scrolled"}>
      <div className="navbar__image-wrapper">
        <img
          src={logo2}
          alt="logo2"
          className="navbar__logo"
          onClick={() => {
            window.open(
              "https://www.coleconomistes.cat/Paginas/Ficha.aspx?IdMenu=A2238BD0-3048-4D9D-AB8C-C91C6FDFD475&Idioma=ca-ES"
            );
          }}
        />
        <Link
          to={"/"}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="navbar__logo"
            style={{ width: "100%" }}
          />
        </Link>
      </div>
      {isMobile === false && (
        <div className="navbar__items-container">
          <NavbarItem
            text={"Dates"}
            color="white"
            cami="datesclaus"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Organització"}
            color="white"
            cami="organitzacio"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Normes"}
            color="white"
            cami="instruccions"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Eixos"}
            color={"white"}
            cami="eixos"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Notícies"}
            color="white"
            cami="noticies"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Contacte"}
            color="white"
            cami="contacte"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
          <NavbarItem
            text={"Patrocinadors"}
            color="white"
            cami="patrocinadors"
            setDisplayHamburgerMenu={setDisplayHamburgerMenu}
          />
        </div>
      )}
      {isMobile === true && displayHamburgerMenu !== true && (
        <GiHamburgerMenu
          color="white"
          size={30}
          style={{ alignSelf: "center", marginRight: "20px" }}
          onClick={() => {
            setDisplayHamburgerMenu(!displayHamburgerMenu);
          }}
        />
      )}
      {isMobile === true && displayHamburgerMenu !== false && (
        <GrClose
          color="white"
          size={30}
          style={{ alignSelf: "center", marginRight: "20px" }}
          onClick={() => {
            setDisplayHamburgerMenu(!displayHamburgerMenu);
          }}
        />
      )}
      {displayHamburgerMenu === true && (
        <div className="navbar__dropdown-menu">
          {" "}
          <div className="navbar__items-container">
            <NavbarItem
              text={"Dates"}
              color="white"
              cami="datesclaus"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            <NavbarItem
              text={"Organització"}
              color="white"
              cami="organitzacio"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            <NavbarItem
              text={"Normes"}
              color="white"
              cami="instruccions"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            {/* <NavbarItem text={"Història"} color="white" path="historia"  setDisplayHamburgerMenu={setDisplayHamburgerMenu}/> */}
            <NavbarItem
              text={"Eixos"}
              color={"white"}
              cami="eixos"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            <NavbarItem
              text={"Notícies"}
              color="white"
              cami="noticies"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            <NavbarItem
              text={"Contacte"}
              color="white"
              cami="contacte"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
            <NavbarItem
              text={"Patrocinadors"}
              color="white"
              cami="patrocinadors"
              setDisplayHamburgerMenu={setDisplayHamburgerMenu}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
